import React from 'react'
import logo_nobg from '../../assets/logos/logo-wt2.png';
import {Link} from 'react-router-dom'
import { Facebook, Instagram, Youtube, Twitter, TelephoneFill, EnvelopeFill, Linkedin } from 'react-bootstrap-icons';
import cards from '../../assets/images/categories/cards.webp'
import TrustBox from './TrustBox';

const Footer = () => {
  return (
        <>
            <footer>
                <div className="footer-bg" >
                    <div className='container-zk py-5'>
                        <div className='row text-white gx-5 py-4'>
                            <div className='col-lg-4 col-md-3 mb-3'>
                                <img src={logo_nobg} className='img-fluid footer-logo' alt='logo' />
                                <div className='mt-4'>
                                    <ul className='list-unstyled'>
                                        <li><a href='tel:5715997989'  className='text-decoration-none text-light lh-lg fs-zk-17'><TelephoneFill className='text-zk-primary me-2'/> +1 571 599 7989</a></li>
                                        <li><a className='text-decoration-none text-light lh-lg' href='mailto:info@examevouchers.com'><EnvelopeFill className='text-zk-primary me-2'/> info@examevouchers.com</a></li>
                                    </ul>
                                    {/* <div className='mt-3'>
                                        <div className="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="646bd6548598295b662c846c" data-style-height="24px" data-style-width="100%" data-theme="dark" data-min-review-count="0" data-without-reviews-preferred-string-id="3" data-style-alignment="start">
                                            <a href="https://www.trustpilot.com/review/examevouchers.com" target="_blank" rel="noopener" className='text-decoration-none text-white'>Trustpilot</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3'>
                                <div className='text-capitalize fs-zk-20' style={{fontWeight:"600"}}>Quick Links</div>
                                <hr className='border-zk-primary mb-3 w-100px mt-1'/>
                                <ul className='list-unstyled'>
                                    <li><Link to='/about-us' className='text-decoration-none text-light lh-lg'>About Us</Link></li>
                                    <li><Link to='/contact-us' className='text-decoration-none text-light lh-lg'>Contact Us</Link></li>
                                    <li><Link to='/referrals' className='text-decoration-none text-light lh-lg'> Referrals Program </Link></li>
                                    <li><Link to='/faqs' className='text-decoration-none text-light lh-lg'>FAQs</Link></li>
                                    <li><Link to='/privacy-policy' className='text-decoration-none text-light lh-lg'>Privacy Policy</Link></li>
                                    <li><Link to='/terms-and-conditions' className='text-decoration-none text-light lh-lg'>Terms & Conditions</Link></li>
                                </ul>
                            </div>
                            <div className='col-lg-2 col-md-3'>
                                <div className='text-capitalize fs-zk-20' style={{fontWeight:"600"}}>Vouchers</div>
                                <hr className='border-zk-primary mb-3 w-100px mt-1'/>
                                <ul className='list-unstyled'>
                                    <li><Link to='/exam-categories/comptia' className='text-decoration-none text-light lh-lg'>CompTIA</Link></li>
                                    <li><Link to='/exam-categories/microsoft' className='text-decoration-none text-light lh-lg'>Microsoft</Link></li>
                                </ul>
                            </div>
                            <div className='col-lg-3 col-md-3'>
                                <div className='text-capitalize fs-zk-20' style={{fontWeight:"600"}}>Follow Us</div>
                                <hr className='border-zk-primary mb-3 w-100px mt-1'/>
                                <div className="flex-wrap d-flex mx-auto mt-3 ">
                                    <div className="social-links py-2 text-center">
                                        <a href="https://www.facebook.com/examevouchers" className="text-decoration-none text-zk-primary" target="_black" >
                                        <Facebook size={26} className="mx-2 home-social-link"/>
                                        </a>
                                        <a href="https://www.instagram.com/examevouchers1/" className="text-decoration-none text-zk-primary" target="_black" >
                                        <Instagram size={26} className="mx-2 home-social-link"/>
                                        </a>
                                        {/* <a href="https://twitter.com" className="text-decoration-none text-zk-primary" target="_black" >
                                        <Twitter size={26} className="mx-2 home-social-link"/>
                                        </a> */}
                                        {/* linkedin */}
                                        {/* <a href="" className="text-decoration-none text-black" target="_black" >
                                        <Youtube size={26} className="mx-2 home-social-link"/>
                                        </a> */}
                                        {/* youtube */}
                                        <a href="https://www.linkedin.com/company/examevouchers/" className="text-decoration-none text-black" target="_black" >
                                        <Linkedin size={26} className="mx-2 home-social-link"/>
                                        </a>
                                        
                                    </div>
                                </div>
                                <div className='text-capitalize fs-zk-20 mt-4' style={{fontWeight:"600"}}>Payment Methods</div>
                                <hr className='border-zk-primary mb-3 w-100px mt-1'/>
                                <div className='mt-3'>
                                    <img src={cards} className='img-fluid card-footer' alt='cards' />
                                </div>
                                

                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='py-2 bg-zk-primary'>
                    <div className='container-zk'>
                        <div className='row text-white'>
                            <div className='col-md-12'>
                                <div className='d-flex justify-content-around'>
                                    <p className='text-light py-0 my-0 fs-zk-14'>Copyright © All Right Reserved {
                                            new Date().getFullYear()
                                        }
                                    .</p>
                                    <p className='text-light py-0 my-0 fs-zk-14'>Powered by <b><a href="https://simplesolutionz.org/" rel="noreferrer" target="_blank" className='text-white text-decoration-none'>Simple Solutionz LLC</a></b></p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </footer>
        </>
  )
}

export default Footer
