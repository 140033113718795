import React,{useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Search } from 'react-bootstrap-icons'

const BannerCategory = () => {
    
    const [searchQuery, setSearchQuery] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();

    const searchHandler = (e) => {
        e.preventDefault();
        if(searchQuery.length > 0){
            navigate(`/search/keyword?query=${searchQuery}`, { replace: true });
        }
    };
    return (
        <>
            <section id="website-responsive">
                <div className='container-zk'>
                    <div className='home-sidebar-shop'>
                        <div className='bg-zk-secondary px-4 '>
                            <div className='row align-items-center py-lg-0 py-2'>
                                <div className='col-md-4 col-6 border-end-header'>
                                    <div className='text-center pt-4 p-md-4'>
                                    <Link to="/exam-categories/comptia" className="text-white text-decoration-none fs-zk-22">CompTIA</Link>
                                    </div>
                                </div>
                                <div className='col-md-4 col-6 border-end-header border-md-end-header'>
                                    <div className='text-center pt-4 p-md-4'>
                                    <Link to="/exam-categories/microsoft" className="text-white text-decoration-none fs-zk-22">Microsoft </Link>
                                    </div>
                                </div>
                                <div className='col-md-4 col-12'>
                                    <div className='py-4 ps-lg-5'>
                                        {/* <a href="tel:123456789" className='text-decoration-none text-white fs-zk-17 fw-500 footer-link-hover'>Call Us</a> */}
                                        {/* <div className='fs-zk-17 fw-500 text-white pb-2'>Search By Test Name</div> */}
                                        <form className="form-inline my-lg-0" onSubmit={searchHandler}>
                                            <div className="input-group w-100">
                                                <input className="form-control rounded-0 fs-zk-15 " type="text" placeholder="Search Exam" id="example-search-input" value={searchQuery} onChange={e=>setSearchQuery(e.target.value)}/>
                                                <span className="input-group-append">
                                                    <button className="btn rounded-0 btn-light fs-zk-15 pb-2" name="Search " type="button" aria-label="search" onClick={searchHandler}><Search /></button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default BannerCategory