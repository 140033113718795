import React,{useEffect} from 'react'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import BannerCategory from '../components/common/BannerCategory'            
import banner from '../assets/images/referrals/banner.webp'
import banner_mb from '../assets/images/referrals/banner_mb.webp'
import Popup from './Popup';
import { Helmet } from 'react-helmet'
import Icon1 from '../assets/images/referrals/partnership.webp'
import Icon2 from '../assets/images/referrals/star.webp'
import Icon3 from '../assets/images/referrals/gift.webp'
import { Link } from 'react-router-dom'
import PopupFormReferrals from '../components/referrals/PopupFormReferrals'
// import { Link } from 'react-router-dom'

const Referrals = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title> Referrals | Exam E Vouchers  </title>
            <meta name="description" content="" />
            <link rel="canonical" href="https://examevouchers.com/faqs"/>
        </Helmet>    

        <div id="popup">
            {props.isOpen && <Popup
            content={<>
                <img src={props.adImg} alt="Exam E-Vouchers" className='img-fluid' />
            </>}
            handleClose={props.togglePopup}
            />}
        </div>
        <Header cartOperation={props.cartOperation} cartState={props.cartState} setShowCanvas={props.setShowCanvas} showCanvas={props.showCanvas} DetailCartClose={props.DetailCartClose}/>
            <PopupFormReferrals/>
            <section>
                <div id='referrals' className='banner-section-referrals'>
                    <img className="img-fluid d-none d-sm-none d-md-block" alt='FAQ' src={banner} style={{ minWidth:"100%"}}/>
                    <img className="img-fluid d-md-none d-sm-block" alt='FAQ' src={banner_mb} style={{ minWidth:"100%"}}/>
                    <div className='container-zk'>
                        <div className='page-banner-content text-start'>
                            <div className='page-banner-title '>
                            <div className='text-transform-capitalize text-oswald text-zk-secondary lh-sm text-white' style={{fontWeight: "600"}}> 
                                Get a Reward <br className='' /> 
                                for Every Friend <br className='' />
                                you Refer
                            </div>
                            <div className='mt-2 mt-md-3'>
                                <span className='btn btn-zk-white text-decoration-none fs-zk-18 rounded-5 px-4 text-zk-primary text-uppercase' style={{fontWeight: "600"}}  data-bs-toggle="modal" data-bs-target="#exampleModal"> Invite Friends Now </span>
                            </div>    
                            </div>                        
                        </div>
                    </div>
                </div>
            </section>
            <div className='bg-page' id="website-responsive">
                <section className=''>
                    <div className='container-zk2'>
                        <div className='row pt-md-4 mb-md-4' id="">
                            <div className="col-md-12 p-3">
                                <div className="py-4 text-center" id="page-section-title">
                                    <div className='fs-zk-35 lh-zk text-zk-primary' style={{fontWeight:"600"}}>
                                        How Does It Work?
                                        <hr className='border-zk-black w-100px py-0 my-1 mx-auto'/>
                                    </div>
                                    <div className='fs-zk-18 mt-3 text-uppercase' style={{fontWeight:600}}>
                                        From Referral to Reward
                                    </div>
                                    <div className='fs-zk-16' style={{fontWeight:400}}>
                                    *This offer is only valid for CompTIA Certifications 
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <div className='text-center pb-4'>
                        <div className='row justify-content-evenly gy-5' id="">
                            <div className='col-8 col-md-3'>
                                <div className='text-center'>
                                    <img src={Icon1} className='img-fluid how-it-works-images px-4 px-lg-5' alt='secure'/>
                                </div>
                                <div className='mt-3'>
                                    <div className='fs-zk-22 text-uppercase lh-zk ' style={{fontWeight:"600"}}>
                                    Join
                                    </div>
                                    <div className='fs-zk-16 mt-2' style={{fontWeight:600}}>
                                    Join Referral Program
                                    </div> 
                                </div>
                            </div>
                            <div className='col-8 col-md-3'>
                                <div className='text-center'>
                                    <img src={Icon2} className='img-fluid how-it-works-images px-4 px-lg-5' alt='secure'/>
                                </div>
                                <div className='mt-3'>
                                    <div className='fs-zk-22 text-uppercase lh-zk ' style={{fontWeight:"600"}}>
                                    Refer
                                    </div>
                                    <div className='fs-zk-16 mt-2' style={{fontWeight:600}}>
                                    Refer your Friend and You Both Get $10 
                                    </div>
                                </div>
                            </div>
                            <div className='col-8 col-md-3'>
                                <div className='text-center'>
                                    <img src={Icon3} className='img-fluid how-it-works-images px-4 px-lg-5' alt='secure'/>
                                </div>
                                <div className='mt-3'>
                                    <div className='fs-zk-22 text-uppercase lh-zk ' style={{fontWeight:"600"}}>
                                    Earn
                                    </div>
                                    <div className='fs-zk-16 mt-2' style={{fontWeight:600}}>
                                    Earn Rewards for Each Referral
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className='text-center'>
                        <div className='mt-3'>
                            <span className='btn btn-zk-primary text-decoration-none fs-zk-18 rounded-5 px-4 text-uppercase text-white' style={{fontWeight: "600"}} data-bs-toggle="modal" data-bs-target="#exampleModal"> Invite Friends Now </span>
                        </div>
                        </div>

                    </div>
                </section>
                <section className='faq'>
                    <div className='container-zk'>
                    <div className="pt-5 mt-4 text-center" id="page-section-title">
                        <div className='fs-zk-35 lh-zk text-zk-primary' style={{fontWeight:"600"}}>
                            Referral FAQ
                        </div>
                    </div>
                        <div className='row pb-4' id="faq-home">
                                <div className='col-md-10 mx-auto pb-md-5 py-4' style={{borderRadius:"30px"}}>
                                    <div className='accordion accordion-flush px-md-5 px-2' id='accordionFlushExample'>
                                        <div className='accordion-item'>
                                            <h2 className='accordion-header' id='flush-heading1'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse1' aria-expanded='false' aria-controls='flush-collapse1'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}> Can I refer to multiple friends? </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse1' className='accordion-collapse collapse' aria-labelledby='flush-heading1' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> Yes, there’s no limit! Each successful referral earns you additional rewards.   </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <hr /> */}
                                        
                                        <div className='accordion-item pt-4'>
                                            <h2 className='accordion-header' id='flush-heading2'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse2' aria-expanded='false' aria-controls='flush-collapse2'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}> What if my friend didn’t use my referral link?   </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse2' className='accordion-collapse collapse' aria-labelledby='flush-heading2' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> To receive the discount, your friend must use your unique referral link when purchasing. </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='accordion-item pt-4'>
                                            <h2 className='accordion-header' id='flush-heading3'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse3' aria-expanded='false' aria-controls='flush-collapse3'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}> Can I refer friends for any voucher purchase? </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse3' className='accordion-collapse collapse' aria-labelledby='flush-heading3' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> Referral discounts are available only for CompTIA certification vouchers. </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='accordion-item pt-4'>
                                            <h2 className='accordion-header' id='flush-heading4'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse4' aria-expanded='false' aria-controls='flush-collapse4'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}> How do I use my referral rewards? </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse4' className='accordion-collapse collapse' aria-labelledby='flush-heading4' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> For every successful referral purchase, you earn a $10 reward. You can use your rewards to reduce the cost of voucher purchases on Exam E-Vouchers, paying only the remaining balance after applying your rewards. </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='accordion-item pt-4'>
                                            <h2 className='accordion-header' id='flush-heading5'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse5' aria-expanded='false' aria-controls='flush-collapse5'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}> How long does it take to receive my reward?   </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse5' className='accordion-collapse collapse' aria-labelledby='flush-heading5' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> Rewards are processed within 3 days after your friend completes their purchase. </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='accordion-item pt-4'>
                                            <h2 className='accordion-header' id='flush-heading6'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse6' aria-expanded='false' aria-controls='flush-collapse6'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}>  Can I receive cash payment for my referral rewards? </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse6' className='accordion-collapse collapse' aria-labelledby='flush-heading6' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> No, referral rewards are provided as discounts on future voucher purchases only. </span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='accordion-item pt-4'>
                                            <h2 className='accordion-header' id='flush-heading7'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse7' aria-expanded='false' aria-controls='flush-collapse7'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}> Can I combine referral rewards with other discounts? </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse7' className='accordion-collapse collapse' aria-labelledby='flush-heading7' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> Can’t combine, referral or promotional discount. You can use only one at a time.
                                                     </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='accordion-item pt-4'>
                                            <h2 className='accordion-header' id='flush-heading8'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse8' aria-expanded='false' aria-controls='flush-collapse8'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}> What happens if my friend cancels their order?   </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse8' className='accordion-collapse collapse' aria-labelledby='flush-heading8' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> If your friend cancels their order, the referral reward will not be issued.  </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='accordion-item pt-4'>
                                            <h2 className='accordion-header' id='flush-heading9'>
                                                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapse9' aria-expanded='false' aria-controls='flush-collapse9'>
                                                    <span className='fs-zk-18 text-zk-secondary' style={{fontWeight:"600"}}>  Is there an expiration date for my referral rewards? </span>
                                                </button>
                                            </h2>
                                            <div id='flush-collapse9' className='accordion-collapse collapse' aria-labelledby='flush-heading9' data-bs-parent='#accordionFlushExample'>
                                                <div className='accordion-body'>
                                                    <span className='fs-zk-16'> Referral rewards are valid for 6 months from the date they are issued. </span>
                                                </div>
                                            </div>
                                        </div>
                                  </div>
                                  </div> 
                                  </div> 
                                  
            </div>
        <Footer />
        </section>
        </div>
        </>
    )
}

export default Referrals