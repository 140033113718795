import React from 'react'
import logo from '../assets/logos/logo.png'
const Loading = () => {
    return (
        <>
            <div id="splash-screen" className="splash-screen">
                <img src={logo} alt="Exam Voucher logo" />
                {/* <span>....</span> */}
            </div>
        </>
    )
}

export default Loading