import React,{useState,useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import * as Icon from 'react-bootstrap-icons';
import { useLocation } from "react-router-dom";
import logo from '../../assets/logos/logo.png'
// import logo_wt from '../../assets/logos/logo_wt.webp'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Search, Cart, Facebook, Instagram, Linkedin, Youtube, TelephoneFill, EnvelopeFill, XCircleFill, Cart2, CartFill, Cart3, Trash, TrashFill } from 'react-bootstrap-icons';
// import Dropdown from 'react-bootstrap/Dropdown';
import {toast} from 'react-toastify';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styled, { keyframes } from "styled-components";

const Header = (props) => {
    
    useEffect(()=>{
        setTotal(props.cartState.reduce((a, b) => a + (b.price * b.quantity), 0))
    }, [props.cartState])
    const [total, setTotal] = useState(0)

    const location = useLocation();
    const [searchQuery , setSearchQuery] = useState('');
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const navDropdownTitle = (
        props.cartState.length > 0 ?
        (
            <><Cart size={18} onClick={e=> props.setShowCanvas(true) } style={{cursor:"pointer"}}/><span className='badge bg-zk-primary rounded-pill ms-1 mt-0'>{props?.cartState?.length}</span></>
        )
        :
        (
            <><Cart size={18}  onClick={e=> props.setShowCanvas(true) } style={{cursor:"pointer"}}/></>
        )
    )

    const navigate = useNavigate();

    const searchHandler = (e) => {
        e.preventDefault();
        if(searchQuery.length > 0){
            navigate(`/search/keyword?query=${searchQuery}`, { replace: true });
        }
    };
    return (
        <>
            <div className='fixed-top shadow-sm'>
                {/* <div className='bg-dark pt-1'>
                    <div className='container-zk'>
                    <div className='row text-white'>
                        <div className="col-12">
                        <Marquee>
                            <div className='text-white promo-code-txt'>
                            Get 5% more discount<span className=" ms-md-5"> PROMO CODE:</span> <span className="promo-code me-md-5">LIVE5</span> Valid till December 31st, 2023
                            </div>
                        </Marquee>
                        </div>
                    </div>
                    </div>
                </div> */}
                <div className='bg-zk-secondary'>
                    <div className='container-zk'>
                        <div className='row text-white'>
                            <div className='col-lg-8 col-md-12 pt-2 pb-xl-2 pb-lg-4 pb-md-4 pb-sm-2 pb-2 ps-1'>
                                <div className="flex-wrap d-flex align-items-center fs-np-14-mob">
                                    <div id="contact-number">&nbsp;
                                        <a href="tel:5715997989" className="text-decoration-none text-white">
                                        <TelephoneFill size={14}/> +1 571 599 7989
                                        </a>
                                    </div>
                                    &nbsp; | &nbsp;
                                    <div id="email">
                                        <a href="mailto:info@examevouchers.com" className="text-decoration-none text-white">
                                        <EnvelopeFill size={14} className="ms-lg-3 ms-1"/>  info@examevouchers.com
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='col-md-4 text-md-center d-xl-block d-none d-lg-none my-1'>
                                <div className="flex-wrap p-0 d-flex align-items-center justify-content-end">
                                    <form className="form-inline my-2 my-lg-0 pe-3" onSubmit={searchHandler}>
                                        <div className="input-group">
                                            <input className="form-control rounded20 fs-zk-12 " type="text" placeholder="Search Exam" id="example-search-input" value={searchQuery} onChange={e=>setSearchQuery(e.target.value)}/>
                                            <span className="input-group-append"><button className="btn rounded020 btn-light fs-zk-12" name="Search " type="button" onClick={searchHandler} aria-label="search"><Search /></button></span>
                                        </div>
                                    </form>
                                    {/* <div className='d-flex align-items-center justify-content-center'> */}
                                    {/* <Link to="/cart" className="text-decoration-none text-white mx-3 fs-zk-14">
                                        Login
                                    </Link> |  */} |
                                    {/* <div className="text-decoration-none text-white">
                                        <Cart size={18} className="fw-bolder text-work-sans ms-3" style={{fontWeight:"500"}}/>
                                    </div> */}
                                    <span className='header-card-dropdown ms-3'>
                                        {navDropdownTitle}
                                    </span>

                                    {/* </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Navbar expand="lg" className="bg-white" id="website-nav">
                <Container className='container-zk'>
                    <Navbar.Brand><Link
                    className="flex-wrap navbar-brand d-flex align-items-baseline fs-zk-24"
                    to="/"
                    >
                    <img
                        src={logo}
                        alt="Exam Vouchers Logo"
                        className="img-fluid logo-img"
                    />
                    </Link>
                    </Navbar.Brand>
                    <span className='pe-xl-2 fs-np-16 mx-auto me-1 text-work-sans d-lg-none d-md-block' style={{fontWeight:"500", cursor:"pointer"}}>
                        <span className='header-card-dropdown'>
                            {navDropdownTitle}
                        </span>
                    </span>
                    {/* <span className='pe-xl-2 fs-np-16 mx-auto me-1 text-work-sans d-lg-none d-md-block' style={{fontWeight:"500", cursor:"pointer"}}>
                        <Search size={18} className="fw-bolder text-work-sans me-3" style={{fontWeight:"500"}}/>
                        <Cart size={18} className="fw-bolder text-work-sans" style={{fontWeight:"500"}}/>
                    </span> */}
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
                    <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-xl`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                    placement="start"
                    className=""
                    >
                    <Offcanvas.Header closeButton>

                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                            <Link
                            className="flex-wrap navbar-brand d-flex align-items-baseline "
                            to="/"
                            >
                            <img
                                src={logo}
                                alt="Exam Vouchers Logo"
                                className="py-2 img-fluid"
                                style={{height: "50px"}}
                            />
                            {/* Logo */}
                            </Link>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    {/* <hr /> */}
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 text-open-sans website-np align-items-nav">
                            <Link to="/exam-categories" className={splitLocation[1] === "exam-categories" ? "active py-2 fs-zk-16 nav-link text-open-sans text-uppercase" : "py-2 fs-zk-16 nav-link text-open-sans text-uppercase"}>Exam Voucher</Link>
                            {/* <Link to="/how-it-works" className={splitLocation[1] === "how-it-works" ? "active py-2 fs-zk-16 nav-link text-open-sans text-uppercase" : "py-2 fs-zk-16 nav-link text-open-sans text-uppercase"}>How it Works</Link> */}
                            <Link to="/about-us" className={splitLocation[1] === "about-us" ? "active py-2 fs-zk-16 nav-link text-open-sans text-uppercase" : "py-2 fs-zk-16 nav-link text-open-sans text-uppercase"}>About us</Link>
                            <Link to="/blogs" className={splitLocation[1] === "blogs" ? "active py-2 fs-zk-16 nav-link text-open-sans text-uppercase" : "py-2 fs-zk-16 nav-link text-open-sans text-uppercase"}>Blogs</Link>
                            <Link to="/faqs" className={splitLocation[1] === "faqs" ? "active py-2 fs-zk-16 nav-link text-open-sans text-uppercase" : "py-2 fs-zk-16 nav-link text-open-sans text-uppercase"}>FAQs</Link>
                            <Link to="/contact-us" className={splitLocation[1] === "contact-us" ? "active py-2 fs-zk-16 nav-link text-open-sans text-uppercase" : "py-2 fs-zk-16 nav-link text-open-sans text-uppercase"}>Contact us</Link>
                            
                            {/* <span className=' d-md-block d-sm-none d-none'>
                            <a href="tel:5715997989" className={splitLocation[1] === "/" ? "btn btn-zk-primary text-decoration-none fs-zk-14 lh-lg rounded20 px-4" : "btn btn-zk-primary btn-sm text-decoration-none fs-zk-14 lh-lg rounded20 px-4"}>+1 571 599 7989</a>
                            </span> */}
                            <button className='pe-xl-2 fs-np-16 nav-link text-work-sans d-lg-none w-100 btn btn-zk-primary-light rounded-0 text-white text-uppercase' style={{fontWeight:"500"}} >My Account</button>
                            <div className="flex-wrap d-flex d-lg-none mx-auto mt-3">
                                <div className="social-links pt-2">
                                    <a href="https://facebook.com" className="text-decoration-none text-black" target="_black" >
                                    <Facebook size={26} className="mx-2 home-social-link"/>
                                    </a>
                                    {/* instagram */}
                                    <a href="https://instagram.com" className="text-decoration-none text-black" target="_black" >
                                    <Instagram size={26} className="mx-2 home-social-link"/>
                                    </a>
                                    {/* twitter */}
                                    {/* <a href="https://twitter.com/netpluscollege" className="text-decoration-none text-black" target="_black" >
                                    <Twitter size={26} className="mx-2 home-social-link"/>
                                    </a> */}
                                    {/* linkedin */}
                                    <a href="https://linkedin.com" className="text-decoration-none text-black" target="_black" >
                                    <Linkedin size={26} className="mx-2 home-social-link"/>
                                    </a>
                                    {/* youtube */}
                                    <a href="https://youtube.com" className="text-decoration-none text-black" target="_black" >
                                    <Youtube size={26} className="mx-2 home-social-link"/>
                                    </a>
                                    
                                </div>
                            </div>
                        </Nav>
                    </Offcanvas.Body>
                    </Navbar.Offcanvas>
                    
                </Container>
                </Navbar>
            </div>

            <Offcanvas show={props.showCanvas} onHide={props.DetailCartClose} placement="end" size="lg" style={{width:"350px"}}>
                <Offcanvas.Header closeButton >
                    <Offcanvas.Title className='text-zk-primary text-center mx-auto'><Cart size={20} className="me-2"/>Cart Items</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className=''>
                    <div className='row cart-sidebar'>
                        <div className='col-12'>
                        {props.cartState.length > 0 ? (
                            props.cartState.map((item, index) => {
                                return (
                                    <div className='mb-3 border-bottom py-3 d-flex' key={index}>
                                        <div className='col-7'>
                                            <div className='fs-zk-14' style={{fontWeight:"600"}}>
                                                {item.name}
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='fs-zk-14 fw-600 text-nowrap'>
                                                ${item.price} <sup><del>{item.actual_price}</del></sup>
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <div className='fs-zk-14 fw-600 text-end'>
                                                <div className='float-end' onClick={() => props.cartOperation.removeItemFromCart(item)} style={{cursor:"pointer"}}><TrashFill className='text-zk-primary' size={18}/></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        ) : (
                            <div className='row mb-3'>
                                <div className='col-12'>
                                    <div className='fs-zk-15 fw-600 border-0 alert alert-secondary alert-sm py-2 text-center' style={{fontWeight:"600"}}>Cart is Empty</div>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                    
                    {/* fixed bottom in the canvas */}
                    <div className='row'>
                        <div className='col-12'>
                            <div className='position-absolute bottom-0 pb-4 w-100 bg-white pt-3'>
                                <div className='d-grid mx-4'>
                                    {props.cartState.length > 0 ? (
                                        <>
                                            <div className='divider'/>
                                            <div className='text-center mb-3'>
                                                <span className='fs-zk-18' style={{fontWeight:"700"}}>Subtotal: </span>
                                                <span className='fs-zk-18 ps-3' style={{fontWeight:"700"}}>${total}</span>
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className='d-grid mx-4'>
                                    {/* button disbaled if props.cartState.length = 0 */}
                                    {props.cartState.length === 0 ? (
                                        <button className='btn btn-zk-primary text-decoration-none fs-zk-14 lh-lg rounded20 px-4' onClick={e=>toast.error("Your cart is empty")}>Checkout</button>    
                                    ) : (
                                        <Link to="/checkout" className='btn btn-zk-primary text-decoration-none fs-zk-14 lh-lg rounded20 px-4'>Checkout</Link>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
const movingText = keyframes`
0%{
  transform:translateX(100%);
}
100%{
  transform:translateX(-60%);
}
`;


const Marquee = styled.p`
  @media (prefers-reduced-motion: no-preference) {
    padding: 0.3rem;
    margin: 0;
    animation: ${movingText} 20s linear infinite;
    :hover {
      animation-play-state: paused;
    }
  }
  cursor: default;
`;



export default Header